import {backendApi} from "../backend-api-sr"

export const sales_procurement = (() =>{
    const index = ( async (subPath) => {
        var url = '/v2/kmb/sales-procurement'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const filter = ( async (subPath) => {
        var url = '/v2/kmb/sales-procurement/get-filter'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    const getOffice = ( async (subPath, reqBody) => {
        var url = '/v2/kmb/sales-procurement/get-office'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getCategoryGroup = ( async (subPath, reqBody) => {
        var url = '/v2/kmb/sales-procurement/get-category-group'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const getCategories = ( async (subPath, reqBody) => {
        var url = '/v2/kmb/sales-procurement/get-category'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, reqBody, false, false, false)
    })

    const cost_ratio = ( async (subPath) => {
        var url = '/v2/kmb/sales-procurement/get-cost-ratio'
        if(subPath != null || subPath != ""){
            url = url + subPath;
        }
        return await backendApi.fetchCore(url, null, false, false, false)
    })

    return {index, filter, getOffice, getCategoryGroup, getCategories, cost_ratio};
})()