<template>
    <v-container fluid class="mb-12">
        <v-row class="mb-2">
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item :to="item.href" class="text-subtitle-2 crumb-item" :disabled="item.disabled"
                                exact>
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color"
                        :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout"
                        :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                            <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                            <v-layout column>
                                <div>
                                    <strong>{{ snackbar.title }}</strong>
                                </div>
                                <div>{{ snackbar.text }}</div>
                            </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                            <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined>
                    <v-container fluid>
                        <v-row>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Label by </p>
                                <v-autocomplete 
                                    dense
                                    solo class="ma-0 pa-1 border-12" hide-details=true 
                                    v-model="label"
                                    :items="labels" 
                                    default="" 
                                    item-value="label" 
                                    item-text="label" 
                                    label="Label by"
                                    @change="(event) => getFilter(event)" >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Region </p>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="region"
                                    :items="regions" default="" item-value="region" item-text="region" label="Region"
                                    @change="(event) => getOfficeID(event)" :disabled="disabledRegion" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Branch </p>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="office"
                                    :items="offices" default="" item-value="office_id" item-text="office" label="Branch" :disabled="disabledOffice" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Category Group </p>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="categoryGroup"
                                    :items="categoryGroups" default="" item-value="cat_group" item-text="cat_group"
                                    label="Category Group" @change="(event) => getCategory(event)"
                                    :disabled="disabledCategoryGroup" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Category </p>
                                <v-autocomplete dense solo class="ma-0 pa-1 border-12" hide-details=true v-model="category"
                                    :items="categories" default="" item-value="category_id" item-text="cat_desc"
                                    label="Category" :disabled="disabledCategory" clearable>
                                </v-autocomplete>
                            </v-col>
                            
                        </v-row>
                        <v-row v-if="label === 'date'">
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">From </p>
                                <v-menu 
                                    ref="modal_from" 
                                    v-model="modal_from" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="290" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_from" 
                                            label="Start Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    
                                    <v-date-picker type="date" v-model="date_from" no-title @input="modal_from = false"></v-date-picker>
                                </v-menu>
                                
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">To </p>
                                <v-menu 
                                    ref="modal_to" 
                                    v-model="modal_to" :close-on-content-click="false"
                                    transition="scale-transition" 
                                    offset-y max-width="320px" 
                                    min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field solo 
                                            dense
                                            clearable
                                            v-model="date_to" 
                                            label="End Date" 
                                            persistent-hint
                                            append-icon="mdi-calendar" 
                                            v-bind="attrs" 
                                            v-on="on" 
                                            class="ma-0 pa-0 mb-1 border-12"
                                            hide-details=true></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-else-if="label === 'month'">
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Start Period </p>
                                <v-menu ref="modal" 
                                v-model="modal" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period" 
                                        label="Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period" 
                                        type="month" 
                                        no-title @input="modal = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">To Period </p>
                                <v-menu ref="modal_to_period" 
                                v-model="modal_to_period" 
                                :close-on-content-click="false" 
                                transition="scale-transition" 
                                offset-y 
                                max-width="290px" 
                                min-width="auto">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        dense
                                        clearable
                                        solo 
                                        v-model="period_to" 
                                        label="To Period" 
                                        persistent-hint 
                                        append-icon="mdi-calendar" 
                                        v-bind="attrs" 
                                        v-on="on" 
                                        class="ma-0 border-12" 
                                        hide-details=true>
                                        </v-text-field>
                                    </template>
                                    <v-date-picker 
                                        v-model="period_to" 
                                        type="month" 
                                        no-title @input="modal_to_period = false">
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">Start Year </p>
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="320px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="year"
                                            label="Year"
                                            append-icon="event"
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            class="ma-0 border-12"
                                            readonly
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker"
                                        v-model="date"
                                        :max="picker"
                                        @input="save"
                                        reactive
                                        no-title>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <p class="blue-lcd mb-1 pa-1">To Year </p>
                                <v-menu
                                    ref="menu_to_year"
                                    v-model="menu_to_year"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="320px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            dense
                                            v-model="year_to"
                                            label="To Year"
                                            append-icon="event"
                                            v-bind="attrs"
                                            v-on="on"
                                            solo
                                            class="ma-0 border-12"
                                            readonly
                                            hide-details=true
                                        >
                                        </v-text-field>
                                    </template>
                                    <v-date-picker
                                        ref="picker_to"
                                        v-model="date_to_year"
                                        :max="picker"
                                        @input="save_date_to_year"
                                        reactive
                                        no-title>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="2">
                                <v-btn class="mt-8 border-12" style="padding: 19px;" small color="primary" elevation="2" @click="getPullData()">Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col cols="3">
                <!-- <v-card outlined class="pa-md-5" height="433px">   -->
                    <!-- <p class="cyan-text text-darken-3 m-0">Cost Ratio</p> -->
                    <!-- <v-card-text class="pa-12"> -->
                        <!-- <v-sheet
                            class="mx-auto"
                        >
                            <v-slide-group
                            multiple
                            show-arrows
                            center-active
                            >
                                <v-slide-item class="mx-1" v-for="(cost, key) in cost_ratios" :key="key"> -->
                                    <!-- outlined class="p-3"  -->
                                    <v-card 
                                        
                                        outlined class="pa-md-5" height="433px"
                                        min-width="250" v-for="(cost, key) in cost_ratios" :key="key">
                                        <p class="cyan-text text-darken-3 m-0">Cost Ratio</p>
                                        <v-card-title class="p-1 mb-0 pb-0 mt-3">
                                            <v-row align="center">
                                                <v-col
                                                class="text-weight text-center"
                                                cols="12"
                                                >
                                                    <h1 class="mb-0 pb-0" style="font-weight: 500;">{{$store.getters.convertToCurrencyUs(cost.Prosentase)}} %</h1>
                                                </v-col>
                                            </v-row>
                                        </v-card-title>
                                        <v-list dense class="transparent mt-3 pt-0">
                                            <v-list-item style="min-height: auto !important;">
                                                <v-list-item-title>Total SJ</v-list-item-title>

                                                <v-list-item-icon>
                                                <v-icon class="mt-0 mb-0">mdi-truck-delivery</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-subtitle class="text-right">
                                                    {{$store.getters.convertToCurrencyUs(cost.count_sj)}}
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                            <v-list-item style="min-height: auto !important;">
                                                <v-list-item-title>Weight</v-list-item-title>

                                                <v-list-item-icon>
                                                <v-icon class="mt-0 mb-0">mdi-weight</v-icon>
                                                </v-list-item-icon>

                                                <v-list-item-subtitle class="text-right">
                                                    {{$store.getters.convertToCurrencyUs(cost.wgt)}}
                                                </v-list-item-subtitle>
                                            </v-list-item>
                                        </v-list>
                                    </v-card>
                                <!-- </v-slide-item>
                            </v-slide-group>
                        </v-sheet> -->
                    <!-- </v-card-text>
                </v-card>    -->
            </v-col>
            <v-col cols="3">
                <v-card outlined class="pa-md-5">  
                    <p class="cyan-text text-darken-3 m-0">Summary Sales Procurement by mill type</p>
                    <div id="container_pie" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card outlined class="pa-md-5">  
                    <p class="cyan-text text-darken-3 m-0">Sales Procurement by mill type</p>
                    <div id="container1" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5 mb-10">
            <v-col cols="4">
                <v-card outlined class="pa-md-5">  
                    <p class="cyan-text text-darken-3 m-0">Summary Sales Procurement by division</p>
                    <div id="container_pie_div" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
            <v-col cols="8">
                <v-card outlined class="pa-md-5">  
                    <p class="cyan-text text-darken-3 m-0">Sales Procurement by division</p>
                    <div id="container1_div" style="height: 370px; width: 100%;"></div>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {sales_procurement} from "../../../backend-api/kmb/sales_procurement"
import {backendApi} from "../../../backend-api/backend-api-sr"

export default {
    data() {
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            timeout: 7500,
            search: '',
            breadcumbs: [
                {
                    text: 'KMB',
                    disabled: false,
                    href: '/admin/kmb',
                },
                {
                    text: 'Branch Perfomance',
                    disabled: true,
                    href: '/admin/kmb',
                },
                {
                    text: 'Sales Procurement',
                    disabled: true
                }
            ],
            label: 'date',
            labels: [
                {label:'date'},
                {label:'month'},
                {label:'year'},
            ],
            region: '',
            regions: [],
            disabledRegion: false,
            office: '',
            offices: [],
            disabledOffice: false,
            categoryGroup: '',
            categoryGroups: [],
            disabledCategoryGroup: false,
            date_from:'',
            modal_from:false,
            date_to:'',
            modal_to:false,
            disabledCategory: false,
            categories: [],
            category: '',
            modal: false,
            period: '',
            menu: false,
            year: '',
            picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date:'',
            x: window.matchMedia("(max-width: 991px)"),
            mills: [],
            depos: [],
            mill_pies: [],
            depo_pies: [],
            millsDiv: [],
            deposDiv: [],
            mill_div: [],
            depo_div: [],
            modal_to_period: false,
            period_to: '',
            menu_to_year: false,
            year_to: '',
            date_to_year: '',
            cost_ratios: []
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', false)
        await this.getEnvConf()
        await this.getFilter()
        await this.getGroupCategory()
        this.$refs.picker.activePicker = 'YEAR'    
        this.$refs.picker_to.activePicker = 'YEAR'    
    },
    methods: {
        async getEnvConf(){
            const respVarRegion = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=REGION&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)

            if (respVarRegion.data.data != null) {
                this.region = respVarRegion.data.data
                this.disabledRegion = true

                await this.getOfficeID(this.region)
            } else {
                this.disabledRegion = false
            }

            const respVarBranch = await backendApi.fetchCore(`/api/get_env_var_conf?entity_id=KMB&appl_id=WEBKMB&var_id=BRANCH&groupid=${this.$store.state.kmb.group_id}`, null, false, false, false)

            if (respVarBranch.data.data != null) {
                this.office = respVarBranch.data.data
                this.disabledOffice = true
            } else {
                this.disabledOffice = false
            }
        },
        save_date_to_year(date) {
            console.log(date);

            this.$refs.menu_to_year.save(date)

            this.$refs.picker_to.activePicker = 'YEAR'

            this.menu_to_year = false

            this.year_to = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        save(date) {
            console.log(date);

            this.$refs.menu.save(date)

            this.$refs.picker.activePicker = 'YEAR'

            this.menu = false

            this.year = new Date(date.substr(0, 4)).toISOString().substr(0, 4)
        },
        async getPullData(){
            this.$store.dispatch('setOverlay', true)

            var periodStart = ''
            var periodEnd = ''

            if (this.label === 'date') {
                periodStart = this.date_from ? new Date(this.date_from).toISOString().substr(0, 10) : ''
                periodEnd = this.date_to ? new Date(this.date_to).toISOString().substr(0, 10) : ''
            } else if(this.label === 'month'){
                periodStart = this.period ? new Date(this.period).toISOString().substr(0, 8).replace(/-/g,"") : ''
                periodEnd = this.period_to ? new Date(this.period_to).toISOString().substr(0, 8).replace(/-/g,"") : ''
            } else {
                periodStart = this.year ? new Date(this.year).toISOString().substr(0, 4).replace(/-/g,"") : ''
                periodEnd = this.year_to ? new Date(this.year_to).toISOString().substr(0, 4).replace(/-/g,"") : ''
            }

            await this.getCostRatio(periodStart, periodEnd)

            var respData = await sales_procurement.index(`?display=${this.label ? this.label : 'month'}&region=${this.region ? this.region : ''}&branch=${this.office ? this.office : ''}&categoryGroup=${this.categoryGroup ? this.categoryGroup : ''}&category=${this.category ? this.category : ''}&periodStart=${periodStart ? periodStart : ''}&periodEnd=${periodEnd ? periodEnd : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.mill_pies = respData.data.data.pie_mill
                this.mill_div = respData.data.data.pie_div

                this.mills = respData.data.data.line_mill.M ? respData.data.data.line_mill.M : []
                this.depos = respData.data.data.line_mill.D ? respData.data.data.line_mill.D : []
                this.nonRetailDiv = respData.data.data.line_div.R ? respData.data.data.line_div.R : []
                this.retailDiv = respData.data.data.line_div.N ? respData.data.data.line_div.N : []

                var dt1 = [];
                var dt2 = [];
                var dt_pie = [];
                var dtNonRetail = [];
                var dtRetail = [];
                var dt_pie_div = [];

                for (var i = 0; i < this.mills.length; i++) {
                    if (this.label === 'date') {
                        var label = new Date(this.mills[i].label).toISOString().substr(0, 10)
                    } else {
                        var label = this.mills[i].label
                    }
                    
                    dt1.push({ label: label, y: parseFloat(this.mills[i].y / 1000)});
                }
                for (var i = 0; i < this.depos.length; i++) {
                    if (this.label === 'date') {
                        var label_depo = new Date(this.depos[i].label).toISOString().substr(0, 10)
                    } else {
                        var label_depo = this.depos[i].label
                    }
                    dt2.push({ label: label_depo, y: parseFloat(this.depos[i].y / 1000) });
                }

                for (var i = 0; i < this.mill_pies.length; i++) {
                    if (this.mill_pies[i].label === 'D') {
                        var label_pie = 'DEPO'
                    } else if(this.mill_pies[i].label === 'M'){
                        var label_pie = 'MILL'
                    } else {
                        var label_pie = this.mill_pies[i].label
                    }
                    dt_pie.push({ label: this.mill_pies[i].label, y: parseFloat(this.mill_pies[i].y / 1000), name: label_pie });
                }

                console.log(this.nonRetailDiv)
                console.log(this.retailDiv)
                for (var i = 0; i < this.nonRetailDiv.length; i++) {
                    if (this.label === 'date') {
                        var label_non_retail = new Date(this.nonRetailDiv[i].label).toISOString().substr(0, 10)
                    } else {
                        var label_non_retail = this.nonRetailDiv[i].label
                    }
                    dtNonRetail.push({ label: label_non_retail, y: parseFloat(this.nonRetailDiv[i].y / 1000)});
                }
                for (var i = 0; i < this.retailDiv.length; i++) {
                    if (this.label === 'date') {
                        var label_retail = new Date(this.retailDiv[i].label).toISOString().substr(0, 10)
                    } else {
                        var label_retail = this.retailDiv[i].label
                    }
                    dtRetail.push({ label: label_retail, y: parseFloat(this.retailDiv[i].y / 1000) });
                }

                for (var i = 0; i < this.mill_div.length; i++) {
                    if (this.mill_div[i].label === 'N') {
                        var label_pie = 'RETAIL'
                    } else if(this.mill_div[i].label === 'R'){
                        var label_pie = 'NON RETAIL'
                    } else {
                        var label_pie = this.mill_div[i].label
                    }
                    dt_pie_div.push({ label: this.mill_div[i].label, y: parseFloat(this.mill_div[i].y / 1000), name: label_pie });
                }
                var container = "container1";
                var container_pie = "container_pie";
                var containerDiv = "container1_div";
                var container_pie_div = "container_pie_div";

                await this.getChartPie(dt_pie, container_pie, 'pie');
                await this.getChart(dt1, dt2, "Mill", "Depo", container, 'line');

                await this.getChartPie(dt_pie_div, container_pie_div, 'pie');
                await this.getChart(dtNonRetail, dtRetail, "Non Retail", "Retail", containerDiv, 'line');
                this.$store.dispatch('setOverlay', false)
            } else {
                console.log(respData.data);
                this.snackbar = {
                    color: "error",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Error",
                    text: respData.data.data,
                    visible: true
                };
                this.$store.dispatch('setOverlay', false)

                var container = "container1";
                var container_pie = "container_pie";
                var containerDiv = "container1_div";
                var container_pie_div = "container_pie_div";

                await this.getChartPie([], [], 'pie');
                await this.getChart([], [], "Mill", "Depo", container, 'line');

                await this.getChartPie([], [], 'pie');
                await this.getChart([], [], "Non Retail", "Retail", containerDiv, 'line');
                
                return false
            }
        },
        async getFilter(event){
            if (event === '' || event === null) {
                this.region = ''
            } else {
                var respData = await sales_procurement.filter(`?display=${this.label ? this.label : 'month'}`, null, false, false, false)
                if (respData.status === 200) {
                    this.regions = respData.data.region
                }
            }
        },
        async getOfficeID(event){
            if (event === '' || event === null) {
                this.office = ''
            } else {
                var reqBody = {
                    'regionid': this.region
                }
                const respData = await sales_procurement.getOffice('', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.offices = respData.data.office
                }
            }
        },
        async getGroupCategory(event){
            if (event === '' || event === null) {
                this.categoryGroup = ''
            } else {
                var reqBody = {
                    'regionid': this.region ? this.region : ''
                } 
                const respData = await sales_procurement.getCategoryGroup('', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.categoryGroups = respData.data.data
                }
            }
        },
        async getCategory(event){
            if (event === '' || event === null) {
                this.category = ''
            } else {
                var reqBody = {
                    'cat_group': this.categoryGroup ? this.categoryGroup : ''
                } 
                const respData = await sales_procurement.getCategories('', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.categories = respData.data.data
                }
            }
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        explodePie (e) {
            if(typeof (e.dataSeries.dataPoints[e.dataPointIndex].exploded) === "undefined" || !e.dataSeries.dataPoints[e.dataPointIndex].exploded) {
                e.dataSeries.dataPoints[e.dataPointIndex].exploded = true;
            } else {
                e.dataSeries.dataPoints[e.dataPointIndex].exploded = false;
            }
            e.chart.render();

        },
        getChart(dt1, dt2, name1, name2, container, type){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    title: "TON",
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                {
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name1,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    color: "#2d4059",
                    yValueFormatString: "##0.00",
                    // yValueFormatString: "#,###,,,.##",
                },
                {
                    type: type,
                    indexLabel: "{y}",
                    indexLabelFontSize: 14,
                    indexLabelFontColor: "#FFF",
                    name: name2,
                    showInLegend: true,
                    indexLabelPlacement: "inside",  
                    indexLabelOrientation: "vertical",
                    // color: "#2d4059",
                    color: "#ed6663",
                    yValueFormatString: "##0.00",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        getChartPie(dt1, container, type){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                backgroundColor: "transparent",
                exportEnabled: true,
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true,
                    yValueFormatString: "##0.00",
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.explodePie
                },
                data: [
                {
                    type: type,
                    indexLabel: "{label} {y} (#percent%)",
                    indexLabelFontSize: 14,
                    showInLegend: true,
                    yValueFormatString: "##0.00",
                    toolTipContent: "{name}: <strong>{y} TON</strong>",
                    // yValueFormatString: "#,###,,,.##",
                }]
            });

            chart.options.data[0].dataPoints = dt1;

            if (this.x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        async getCostRatio(periodStart, periodEnd){
            this.cost_ratios = []
            var respData = await sales_procurement.cost_ratio(`?display=${this.label ? this.label : 'month'}&region=${this.region ? this.region : ''}&branch=${this.office ? this.office : ''}&categoryGroup=${this.categoryGroup ? this.categoryGroup : ''}&category=${this.category ? this.category : ''}&periodStart=${periodStart ? periodStart : ''}&periodEnd=${periodEnd ? periodEnd : ''}`, null, false, false, false)
            if (respData.status === 200) {
                this.cost_ratios = respData.data.data ? respData.data.data : []
                console.log(this.cost_ratios);
            }
        }
    },
    watch: {
        menu (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
        },
        menu_to_year (val) {
            console.log(val);
            val && setTimeout(() => (this.$refs.picker_to.activePicker = 'YEAR'))
        },
    }
}
</script>
